footer{
	width: 100%;
	background-color: black;
	nav{
		width: 80%;
		margin: auto;
		height: 60px;
		ul{
			margin: 0;
			li{
				float: left;
				list-style: none;
				width: 25%;
				a{
					text-decoration: none;
					text-align: center;
					height: 60px;
					padding: 10px 30px;
					display: block;
					color: white;
					margin: 0;
					&:hover{
						color: black;
						background-color: #efefef;
					}
				}
			}
		}
	}
}