form {
	li{
		margin: 0;
	}
	fieldset{
		border: 0;
		margin: 50px 0;
		padding: 0;
	}
	div{
		.clearfix();
		label{
		/* font-size: 1.5em; */
		width: 20%;
		float: left;
		display: block;
		margin: 10px 0 20px;
		padding: 11px;
		@media @mobile{
			float: none;
			width: 100%;
			padding-left: 0;
			padding-bottom: 0;
			margin-bottom: 0;
		}
		}
		input {
			height: 40px;
			padding: 8px;
			display: block;
			width: 80%;
			margin: 10px 0 20px;
			outline: none;
			border: solid 1px;
			border-color: rgb(204, 204, 204);
			&:focus {
				*.onfocus {
					display: block;
					outline: none;
					color: red;
				}
			}
			@media @mobile{
				float: none;
				width: 100%;
			}
		}
		select{
			height: 40px;
			padding: 8px;
			display: block;
			width: 80%;
			margin: 10px 0 20px;
			outline: none;
			border-radius: 0;
			border: solid 1px;
			border-color: rgb(204, 204, 204);
			@media @mobile{
				float: none;
				width: 100%;
			}
		}
		.preview{
		width: 300px;
		margin-left: 20%;
		display: block;
		@media @mobile{
			margin-left: 0;
			width: 100%;
		}
	}
		}
		input[type="file"]{
			border: none;
			@media @mobile{
				padding-left: 0;
			}
	}
	select {
		height: 40px;
		padding: 8px;
		display: block;
		width: 100%;
		margin: 10px 0 20px;
		border: none;
		border-radius: 5px;
		outline: none;
	}
	textarea {
		width: 80%;
		background-color: #efefef;
		float: left;
		@media @mobile{
			width: 100%;
			float: none;
		}
	}
}
*:focus{
	box-shadow: none;
}
div[unselectable]{
		float: left;
}
.btn{
	display: inline-block;
	padding: 11px 30px;
	background-color: black;
	color: white;
	width: auto;
	min-width: 20%;
	margin-left: 20%;
	font-size: 14px;
	font-family: 'catalog_regular';
	text-align: center;
	text-decoration: none;
	border: none;
	&:hover{
		background-color: #232323;
	}
	@media @mobile{
		margin-left: 0;
	}
}
.send-btn-box{
	margin-top: 150px;
	@media @mobile{
		margin-top: 80px;
	}
}
.posts-edit-box{
	position: fixed;
	top: 0;
	right: 50px;
	ul{
		margin: 0;
		padding: 0;
		li{
			list-style-type: none;
		}
	}
}
.save{
	height: 50px;
	background-color: rgba(0, 0, 0, 0.7);	
	&:hover{
		background-color: rgba(50, 50, 50, 0.8);
	}
}
.cke{
	width: 80%!important;
	float: right;
	margin: 0;
	border: none!important;
	margin-bottom: 50px!important;
	@media @mobile {
		width: 100%!important;
		margin-top: 10px!important;
		height: 250px;
	}
}