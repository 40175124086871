#posts{
	ul{
		margin: 0;
		padding: 0;
		li{
			list-style-type: none;
		}
	}
}
#fileupload{
	.uploadbtn{
		
	}
}

#done{
	.message{
		border: 1px solid black;
		padding: 20px;
		display: inline-block;
		a{
			font-size: 14px
		}
	}
}
.no-marg{
	margin: 0;
}
html body section img[src=""]{
	display: none;
}
.save{
	margin: 0;
}
