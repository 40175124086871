*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
html {
  height: 100%;
  font-size: 14px;
}
@media screen and (max-width: 991px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  html {
    font-size: 10px;
  }
}
body {
  width: 100%;
  min-width: 320px;
  height: 100%;
  overflow-y: scroll;
  margin: 0;
  background-color: #f1f1f1;
}
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.scrolloff {
  pointer-events: none;
}
::-webkit-selection {
  background-color: #000000;
  color: #ffffff;
}
::-moz-selection {
  background-color: #000000;
  color: #ffffff;
}
::-ie-selection {
  background-color: #000000;
  color: #ffffff;
}
::-o-selection {
  background-color: #000000;
  color: #ffffff;
}
::selection {
  background-color: #000000;
  color: #ffffff;
}
@font-face {
  font-family: 'areal';
  src: url('../fonts/areal/areal.eot') format('eot'), url('../fonts/areal/areal.woff') format('woff'), url('../fonts/areal/areal.ttf') format('truetype'), url('../fonts/areal/areal.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'catalog_italic';
  src: url('../fonts/catalog/catalog_italic.eot') format('eot'), url('../fonts/catalog/catalog_italic.woff') format('woff'), url('../fonts/catalog/catalog_italic.ttf') format('truetype'), url('../fonts/catalog/catalog_italic.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'catalog_regular';
  src: url('../fonts/catalog/catalog_regular.eot') format('eot'), url('../fonts/catalog/catalog_regular.woff') format('woff'), url('../fonts/catalog/catalog_regular.ttf') format('truetype'), url('../fonts/catalog/catalog_regular.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'karla_regular';
  src: url('../fonts/karla/Karla-Regular-webfont.eot') format('eot'), url('../fonts/karla/Karla-Regular-webfont.woff') format('woff2'), url('../fonts/karla/Karla-Regular-webfont.woff') format('woff'), url('../fonts/karla/Karla-Regular-webfont.ttf') format('truetype'), url('../fonts/karla/Karla-Regular-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'karla_regular_italic';
  src: url('../fonts/karla/Karla-Italic-webfont.eot') format('eot'), url('../fonts/karla/Karla-Italic-webfont.woff') format('woff2'), url('../fonts/karla/Karla-Italic-webfont.woff') format('woff'), url('../fonts/karla/Karla-Italic-webfont.ttf') format('truetype'), url('../fonts/karla/Karla-Italic-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'karla_bold_italic';
  src: url('../fonts/karla/Karla-Bold-webfont.eot') format('eot'), url('../fonts/karla/Karla-Bold-webfont.woff') format('woff2'), url('../fonts/karla/Karla-Bold-webfont.woff') format('woff'), url('../fonts/karla/Karla-Bold-webfont.ttf') format('truetype'), url('../fonts/karla/Karla-Bold-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'karla_regular_italic';
  src: url('../fonts/karla/Karla-BoldItalic-webfont.eot') format('eot'), url('../fonts/karla/Karla-BoldItalic-webfont.woff') format('woff2'), url('../fonts/karla/Karla-BoldItalic-webfont.woff') format('woff'), url('../fonts/karla/Karla-BoldItalic-webfont.ttf') format('truetype'), url('../fonts/karla/Karla-BoldItalic-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'roboto_light';
  src: url('../fonts/roboto/Roboto-Light-webfont.eot') format('eot'), url('../fonts/roboto/Roboto-Light-webfont.woff') format('woff'), url('../fonts/roboto/Roboto-Light-webfont.ttf') format('truetype'), url('../fonts/roboto/Ratalo-Light-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'roboto_light_italic';
  src: url('../fonts/roboto/Roboto-LightItalic-webfont.eot') format('eot'), url('../fonts/roboto/Roboto-LightItalic-webfont.woff') format('woff'), url('../fonts/roboto/Roboto-LightItalic-webfont.ttf') format('truetype'), url('../fonts/roboto/Roboto-LightItalic-webfont.svg#svgFontName') format('svg');
}
/* *{
	font-family: 'catalog_regular';
	font-size: 1.5rem;
} */
h1 {
  font-family: 'areal';
  font-size: 3.5rem;
  margin: 30px 0;
  text-transform: uppercase;
}
h2 {
  font-family: 'areal';
  font-size: 3rem;
  margin: 30px 0 20px;
  text-transform: uppercase;
}
h3 {
  font-family: 'areal';
  font-size: 2.5rem;
  margin: 30px 0 15px;
}
h4 {
  font-family: 'areal';
  font-size: 2.2rem;
  margin: 30px 0 10px;
}
h5 {
  font-family: 'areal';
  font-size: 1.8rem;
  margin: 30px 0 5px;
}
h6 {
  font-family: 'areal';
  font-size: 1.5rem;
  margin: 30px 0 5px;
}
p {
  font-family: 'catalog_regular';
  font-size: 1.5rem;
  margin: 5px 0;
}
b {
  font-family: 'catalog_regular';
  font-size: 1.5rem;
}
a {
  font-family: 'catalog_regular';
  font-size: 1.5rem;
  margin: 5px 0;
  color: black;
}
a.mail {
  display: block;
  text-decoration: none;
}
ul,
ol {
  padding: 0 20px;
}
ul li,
ol li {
  font-family: 'catalog_regular';
  font-size: 1.5rem;
  margin: 5px 0;
}
table {
  word-break: break-word;
  border-color: gray;
  border-spacing: 2px;
  border-collapse: collapse;
}
table th {
  font-size: 1.5rem;
  text-align: left;
}
table tr {
  border-color: inherit;
}
table tr.grey {
  background-color: #efefef;
}
table tr td {
  font-size: 1.5rem;
  padding-right: 40px;
  padding-bottom: 5px;
  vertical-align: top;
  border: 1px solid #e0e0e0;
  padding: 5px;
}
@media screen and (max-width: 767px) {
  table {
    width: 100%;
  }
  table tr td {
    width: 50%;
  }
}
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?focex5');
  src: url('../fonts/icomoon.eot?#iefixfocex5') format('rembedded-opentype'), url('../fonts/icomoon.ttf?focex5') format('truetype'), url('../fonts/icomoon.woff?focex5') format('woff'), url('../fonts/icomoon.svg?focex5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-e:before {
  content: "\ea50";
}
.icon-e2::after {
  content: "\ea50";
  transform: rotate(180deg);
  font-family: 'icomoon';
  display: inline-block;
  width: 1rem;
  padding-right: 2rem;
}
article.sheet {
  background-color: white;
  padding: 7% 10%;
  *zoom: 1;
}
article.sheet:before,
article.sheet:after {
  display: table;
  content: "";
  line-height: 0;
}
article.sheet:after {
  clear: both;
}
article.sheet:before,
article.sheet:after {
  display: table;
  content: "";
  line-height: 0;
}
article.sheet:after {
  clear: both;
}
article.sheet h2 {
  margin: 0 0 45px 0;
  text-align: left;
}
article.sheet img {
  display: block;
  margin: 20px 0;
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  article.sheet img {
    width: auto;
    max-width: 100%;
  }
}
@media screen and (max-width: 991px) {
  article.sheet {
    padding: 10% 5%;
  }
}
section {
  /* min-height: 100%; */
  width: 100%;
  padding: 0;
  /* min-height: 100%; */
  padding: 75px 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  section {
    padding: 35px 0;
  }
}
.bg {
  position: absolute;
  min-width: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.tabel {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
}
.tabel .tabelcell {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}
section.inner {
  width: 90%;
  margin: auto;
}
@media screen and (max-width: 991px) {
  section.inner {
    width: 95%;
  }
}
#start {
  height: 100%;
  width: 100%;
}
#start .tabel span img {
  width: 20%;
}
@media screen and (max-width: 767px) {
  #start .tabel span img {
    width: 50%;
  }
}
#start,
#welcome,
#erster_tag,
#weiteres {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  padding: 0;
}
#start .tabel .tabelcell > span,
#welcome .tabel .tabelcell > span,
#erster_tag .tabel .tabelcell > span,
#weiteres .tabel .tabelcell > span {
  color: white;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.9);
  font-size: 3.5rem;
  font-family: 'areal';
  padding: 25px;
}
@media screen and (max-width: 991px) {
  #start .tabel .tabelcell > span,
  #welcome .tabel .tabelcell > span,
  #erster_tag .tabel .tabelcell > span,
  #weiteres .tabel .tabelcell > span {
    display: block;
    width: 80%;
    margin: auto;
  }
}
#pate {
  *zoom: 1;
}
#pate:before,
#pate:after {
  display: table;
  content: "";
  line-height: 0;
}
#pate:after {
  clear: both;
}
#pate:before,
#pate:after {
  display: table;
  content: "";
  line-height: 0;
}
#pate:after {
  clear: both;
}
#pate div {
  float: left;
  width: 50%;
}
#pate div img {
  margin: auto;
  display: block;
  margin-bottom: 40px;
}
#pate div.left {
  padding-right: 15px;
}
#pate div.right {
  padding-left: 15px;
}
#pate {
  *zoom: 1;
}
#pate:before,
#pate:after {
  display: table;
  content: "";
  line-height: 0;
}
#pate:after {
  clear: both;
}
#pate:before,
#pate:after {
  display: table;
  content: "";
  line-height: 0;
}
#pate:after {
  clear: both;
}
#pate .pate-person {
  width: 100%;
  float: left;
  margin: 25px 0;
  *zoom: 1;
}
#pate .pate-person:before,
#pate .pate-person:after {
  display: table;
  content: "";
  line-height: 0;
}
#pate .pate-person:after {
  clear: both;
}
#pate .pate-person:before,
#pate .pate-person:after {
  display: table;
  content: "";
  line-height: 0;
}
#pate .pate-person:after {
  clear: both;
}
#pate .pate-person .img-box {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
#pate .pate-person .img-box img {
  display: block;
  border-radius: 100px;
  width: 200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  #pate .pate-person .img-box img {
    width: 60%;
  }
}
#pate .pate-person .pate-text {
  text-align: center;
  width: 100%;
}
#login {
  *zoom: 1;
}
#login:before,
#login:after {
  display: table;
  content: "";
  line-height: 0;
}
#login:after {
  clear: both;
}
#login:before,
#login:after {
  display: table;
  content: "";
  line-height: 0;
}
#login:after {
  clear: both;
}
#login .container {
  width: 50%;
  margin: auto;
  *zoom: 1;
}
#login .container:before,
#login .container:after {
  display: table;
  content: "";
  line-height: 0;
}
#login .container:after {
  clear: both;
}
#login .container:before,
#login .container:after {
  display: table;
  content: "";
  line-height: 0;
}
#login .container:after {
  clear: both;
}
#login .container .a {
  width: 50%;
  display: block;
  position: relative;
  padding-top: 50%;
  float: left;
}
#login .container .a.hover > span > .tabel > span,
#login .container .a.clicked > span > .tabel > span {
  display: table-cell;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
#login .container .a img {
  width: 100%;
  max-width: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
}
#login .container .a .over {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#login .container .a .over .tabel {
  display: table;
  width: 100%;
  height: 100%;
}
#login .container .a .over .tabel span {
  vertical-align: middle;
  text-align: center;
  color: white;
  display: none;
}
#login .container .a .over .tabel span a {
  color: white;
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  #login .container {
    width: 100%;
  }
  #login .container .a {
    width: 25%;
    padding-top: 25%;
  }
}
@media screen and (max-width: 767px) {
  #login .container .a {
    width: 80%;
    float: none;
    padding-top: 80%;
    margin: 0 auto 20px;
  }
}
#crossmedia img {
  margin-top: 60px;
}
#alarmanlage {
  *zoom: 1;
}
#alarmanlage:before,
#alarmanlage:after {
  display: table;
  content: "";
  line-height: 0;
}
#alarmanlage:after {
  clear: both;
}
#alarmanlage:before,
#alarmanlage:after {
  display: table;
  content: "";
  line-height: 0;
}
#alarmanlage:after {
  clear: both;
}
#alarmanlage div {
  *zoom: 1;
}
#alarmanlage div:before,
#alarmanlage div:after {
  display: table;
  content: "";
  line-height: 0;
}
#alarmanlage div:after {
  clear: both;
}
#alarmanlage div:before,
#alarmanlage div:after {
  display: table;
  content: "";
  line-height: 0;
}
#alarmanlage div:after {
  clear: both;
}
#alarmanlage div.left {
  padding-right: 15px;
}
@media screen and (max-width: 767px) {
  #alarmanlage div.left {
    padding: 0;
  }
}
#alarmanlage div.right {
  padding-left: 15px;
}
@media screen and (max-width: 767px) {
  #alarmanlage div.right {
    padding: 0;
  }
}
#organigramm img {
  max-width: 100%;
  max-height: 850px;
  margin: auto;
  display: block;
}
#gl {
  *zoom: 1;
}
#gl:before,
#gl:after {
  display: table;
  content: "";
  line-height: 0;
}
#gl:after {
  clear: both;
}
#gl:before,
#gl:after {
  display: table;
  content: "";
  line-height: 0;
}
#gl:after {
  clear: both;
}
#gl img {
  width: 60%;
  display: block;
  margin: 20px auto 150px;
}
#gl .gl-person {
  width: 50%;
  float: left;
  margin: 0 0 80px 0;
  *zoom: 1;
}
#gl .gl-person:before,
#gl .gl-person:after {
  display: table;
  content: "";
  line-height: 0;
}
#gl .gl-person:after {
  clear: both;
}
#gl .gl-person:before,
#gl .gl-person:after {
  display: table;
  content: "";
  line-height: 0;
}
#gl .gl-person:after {
  clear: both;
}
#gl .gl-person .img-box {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
#gl .gl-person .img-box img {
  display: block;
  border-radius: 100px;
  width: 200px;
  margin: auto;
}
@media screen and (max-width: 767px) {
  #gl .gl-person .img-box img {
    width: 60%;
  }
}
#gl .gl-person .gl-text {
  text-align: center;
  *zoom: 1;
}
#gl .gl-person .gl-text:before,
#gl .gl-person .gl-text:after {
  display: table;
  content: "";
  line-height: 0;
}
#gl .gl-person .gl-text:after {
  clear: both;
}
#gl .gl-person .gl-text:before,
#gl .gl-person .gl-text:after {
  display: table;
  content: "";
  line-height: 0;
}
#gl .gl-person .gl-text:after {
  clear: both;
}
@media screen and (max-width: 767px) {
  #gl img {
    width: 100%;
  }
  #gl .gl-person {
    width: 100%;
    float: none;
    margin-bottom: 80px;
  }
}
#level img {
  max-width: 100%;
  margin-bottom: 150px;
}
#orientierung h2 {
  margin-left: 10%;
}
#cars div div img {
  width: 33%;
  float: left;
  border-radius: 100%;
  padding: 5%;
}
@media screen and (max-width: 767px) {
  #cars div div img {
    display: none;
  }
}
#notfall img {
  width: 90%;
}
#notfall img.mob {
  display: none;
}
@media screen and (max-width: 767px) {
  #notfall img.destab {
    display: none;
  }
  #notfall img.mob {
    display: block;
    width: 100%;
  }
}
#evakuation h2 {
  margin-left: 10%;
}
#evakuation p {
  margin-left: 10%;
}
#email-adressen table {
  /* margin: auto; */
}
#email-adressen table th {
  text-align: left;
  padding-top: 50px;
}
#email-adressen table tr td {
  padding: 0 7px;
}
#email-policy .signatur {
  font-family: 'calibri';
  font-size: 11px;
}
#email-policy .signatur a {
  font-family: 'calibri';
  font-size: 11px;
}
#farbkopierer div div img {
  display: block;
  margin: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  #farbkopierer div div img {
    margin-top: 50px;
  }
}
#branding article div img {
  margin: 0 auto;
  width: 200px;
}
@media screen and (max-width: 767px) {
  #branding article div img {
    margin: 0;
    width: 150px;
    margin-bottom: 50px;
  }
}
#briefschaft img {
  width: 100%;
  display: block;
}
.float-box {
  *zoom: 1;
}
.float-box:before,
.float-box:after {
  display: table;
  content: "";
  line-height: 0;
}
.float-box:after {
  clear: both;
}
.float-box:before,
.float-box:after {
  display: table;
  content: "";
  line-height: 0;
}
.float-box:after {
  clear: both;
}
.float-box .left {
  float: left;
  width: 50%;
}
.float-box .right {
  float: right;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .float-box .left {
    float: none;
    width: 100%;
  }
  .float-box .right {
    float: none;
    width: 100%;
  }
}
#probezeit img.destab {
  display: block;
  height: 150px;
}
@media screen and (max-width: 767px) {
  #probezeit img.destab {
    display: none;
  }
}
#probezeit img.mob {
  display: none;
}
@media screen and (max-width: 767px) {
  #probezeit img.mob {
    display: block;
    width: 50%;
    margin: 30px auto 0;
  }
}
#meet-the-ceo img {
  border-radius: 500px;
}
@media screen and (max-width: 767px) {
  #meet-the-ceo img {
    margin: 0 auto 30px;
    margin-bottom: 50px;
    width: 60%;
  }
}
@media screen and (max-width: 767px) {
  #meet-the-ceo .table-cell {
    width: 100%;
    display: block;
  }
}
.hidden {
  display: none!important;
}
.table {
  display: table;
}
.table .table-cell {
  padding-left: 2rem;
  display: table-cell;
}
.table .table-cell:first-child {
  padding-left: 0;
}
.table .table-cell.middle {
  vertical-align: middle;
}
.no-marg {
  margin: 0;
  margin-left: 0;
}
.select-view {
  height: 45px;
  margin: 0 0 5px 0;
  background-color: white;
}
.select-view span {
  width: 15%;
  display: block;
  float: left;
  color: black;
  overflow: hidden;
  padding: 0 15px;
}
.select-view span.large {
  width: 27.5%;
}
.select-view:hover {
  background-color: #dfdfdf;
}
.select-view.top {
  font-weight: bold;
  background-color: black;
  padding: 10px;
}
.select-view.top span {
  color: white;
}
.select-view a {
  display: block;
  padding: 10px;
}
#out-nav {
  position: fixed;
  z-index: 100;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 100%;
}
#out-nav #top-nav {
  position: fixed;
  z-index: 100;
  right: 0;
  *zoom: 1;
}
#out-nav #top-nav:before,
#out-nav #top-nav:after {
  display: table;
  content: "";
  line-height: 0;
}
#out-nav #top-nav:after {
  clear: both;
}
#out-nav #top-nav:before,
#out-nav #top-nav:after {
  display: table;
  content: "";
  line-height: 0;
}
#out-nav #top-nav:after {
  clear: both;
}
#out-nav #top-nav ul {
  margin: 0;
  padding: 0;
  height: 50px;
  width: 50px;
  float: right;
}
#out-nav #top-nav ul li {
  height: 50px;
  list-style-type: none;
  margin: 0;
}
#out-nav #top-nav ul li a.toggler {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 10px 10px 10px;
  background-color: rgba(0, 0, 0, 0.7);
}
#out-nav #top-nav ul li a.toggler span {
  display: block;
  margin: 6px auto 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 1;
}
#out-nav #top-nav ul li a.toggler:hover {
  background-color: rgba(50, 50, 50, 0.8);
}
#out-nav #bottom-nav {
  position: fixed;
  bottom: 0;
  z-index: 100;
  float: right;
  right: 0;
}
#out-nav #bottom-nav ul {
  padding: 0;
  margin: 0;
  *zoom: 1;
}
#out-nav #bottom-nav ul:before,
#out-nav #bottom-nav ul:after {
  display: table;
  content: "";
  line-height: 0;
}
#out-nav #bottom-nav ul:after {
  clear: both;
}
#out-nav #bottom-nav ul:before,
#out-nav #bottom-nav ul:after {
  display: table;
  content: "";
  line-height: 0;
}
#out-nav #bottom-nav ul:after {
  clear: both;
}
#out-nav #bottom-nav ul li {
  list-style-type: none;
  float: left;
  margin: 0;
}
#out-nav #bottom-nav ul li a {
  color: white;
  display: block;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px 10px 10px 10px;
  height: 50px;
  width: 50px;
  text-align: center;
  margin: 0;
}
#out-nav #bottom-nav ul li a:hover {
  background-color: rgba(50, 50, 50, 0.8);
}
#out-nav #bottom-nav ul li a.mirror {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
#out-nav #bottom-nav ul li a.toggler {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 10px 10px 10px;
  background-color: rgba(0, 0, 0, 0.7);
}
#out-nav #bottom-nav ul li a.toggler span {
  display: block;
  margin: 6px auto 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 1;
}
#out-nav #bottom-nav ul li a.toggler:hover {
  background-color: rgba(50, 50, 50, 0.8);
}
#out-nav #out {
  background-color: black;
  position: relative;
  top: 0;
  height: 100%;
  left: 0%;
  padding: 30px 0 0 30px;
  transition: all 0.5s ease-out;
}
#out-nav #out h4 {
  color: white;
}
#out-nav #out > ul {
  padding: 0;
  margin: 0;
}
#out-nav #out > ul li {
  list-style-type: none;
}
#out-nav #out > ul li a {
  font-family: 'areal';
  color: white;
  text-decoration: none;
  display: block;
  padding: 10px;
  text-transform: uppercase;
  /* &.sub-close::after{
						transform: rotate(360deg);
					} */
}
#out-nav #out > ul li a:hover {
  background-color: rgba(50, 50, 50, 0.8);
}
#out-nav #out > ul ul.sub-menu {
  padding: 0;
  display: none;
}
#out-nav #out > ul ul.sub-menu li {
  padding: 0;
}
#out-nav #out > ul ul.sub-menu li a {
  padding-left: 35px;
  font-size: 16px;
}
#out-nav #out > ul ul.sub-menu li ul li a {
  font-size: 15px;
}
#out-nav #out > ul ul.sub-menu.sub-open {
  display: block;
}
#out-nav {
  padding: 0;
}
#out-nav nav#out.move-nav {
  left: -25%;
  overflow-y: scroll;
  margin-right: -17px;
  padding-bottom: 100px;
}
@media screen and (max-width: 1500px) {
  #out-nav nav#out.move-nav {
    left: -45%;
  }
}
@media screen and (max-width: 991px) {
  #out-nav nav#out.move-nav {
    left: -55%;
  }
}
@media screen and (max-width: 767px) {
  #out-nav nav#out.move-nav {
    left: -85%;
  }
}
.cross-1 {
  -moz-transform: rotate(45deg) translate(5px, 7px);
  -webkit-transform: rotate(45deg) translate(5px, 7px);
  -ms-transform: rotate(45deg) translate(5px, 7px);
  -o-transform: rotate(45deg) translate(5px, 7px);
  transform: rotate(45deg) translate(5px, 7px);
  margin: 0;
}
.cross-2 {
  -moz-transform: rotate(-45deg) translate(-3px, -2px);
  -webkit-transform: rotate(-45deg) translate(-3px, -2px);
  -ms-transform: rotate(-45deg) translate(-3px, -2px);
  -o-transform: rotate(-45deg) translate(-3px, -2px);
  transform: rotate(-45deg) translate(-3px, -2px);
  margin: 0;
}
.remove {
  opacity: 0!important;
}
.animate {
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
footer {
  width: 100%;
  background-color: black;
}
footer nav {
  width: 80%;
  margin: auto;
  height: 60px;
}
footer nav ul {
  margin: 0;
}
footer nav ul li {
  float: left;
  list-style: none;
  width: 25%;
}
footer nav ul li a {
  text-decoration: none;
  text-align: center;
  height: 60px;
  padding: 10px 30px;
  display: block;
  color: white;
  margin: 0;
}
footer nav ul li a:hover {
  color: black;
  background-color: #efefef;
}
form li {
  margin: 0;
}
form fieldset {
  border: 0;
  margin: 50px 0;
  padding: 0;
}
form div {
  *zoom: 1;
}
form div:before,
form div:after {
  display: table;
  content: "";
  line-height: 0;
}
form div:after {
  clear: both;
}
form div:before,
form div:after {
  display: table;
  content: "";
  line-height: 0;
}
form div:after {
  clear: both;
}
form div label {
  /* font-size: 1.5em; */
  width: 20%;
  float: left;
  display: block;
  margin: 10px 0 20px;
  padding: 11px;
}
@media screen and (max-width: 767px) {
  form div label {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
form div input {
  height: 40px;
  padding: 8px;
  display: block;
  width: 80%;
  margin: 10px 0 20px;
  outline: none;
  border: solid 1px;
  border-color: #cccccc;
}
form div input:focus *.onfocus {
  display: block;
  outline: none;
  color: red;
}
@media screen and (max-width: 767px) {
  form div input {
    float: none;
    width: 100%;
  }
}
form div select {
  height: 40px;
  padding: 8px;
  display: block;
  width: 80%;
  margin: 10px 0 20px;
  outline: none;
  border-radius: 0;
  border: solid 1px;
  border-color: #cccccc;
}
@media screen and (max-width: 767px) {
  form div select {
    float: none;
    width: 100%;
  }
}
form div .preview {
  width: 300px;
  margin-left: 20%;
  display: block;
}
@media screen and (max-width: 767px) {
  form div .preview {
    margin-left: 0;
    width: 100%;
  }
}
form input[type="file"] {
  border: none;
}
@media screen and (max-width: 767px) {
  form input[type="file"] {
    padding-left: 0;
  }
}
form select {
  height: 40px;
  padding: 8px;
  display: block;
  width: 100%;
  margin: 10px 0 20px;
  border: none;
  border-radius: 5px;
  outline: none;
}
form textarea {
  width: 80%;
  background-color: #efefef;
  float: left;
}
@media screen and (max-width: 767px) {
  form textarea {
    width: 100%;
    float: none;
  }
}
*:focus {
  box-shadow: none;
}
div[unselectable] {
  float: left;
}
.btn {
  display: inline-block;
  padding: 11px 30px;
  background-color: black;
  color: white;
  width: auto;
  min-width: 20%;
  margin-left: 20%;
  font-size: 14px;
  font-family: 'catalog_regular';
  text-align: center;
  text-decoration: none;
  border: none;
}
.btn:hover {
  background-color: #232323;
}
@media screen and (max-width: 767px) {
  .btn {
    margin-left: 0;
  }
}
.send-btn-box {
  margin-top: 150px;
}
@media screen and (max-width: 767px) {
  .send-btn-box {
    margin-top: 80px;
  }
}
.posts-edit-box {
  position: fixed;
  top: 0;
  right: 50px;
}
.posts-edit-box ul {
  margin: 0;
  padding: 0;
}
.posts-edit-box ul li {
  list-style-type: none;
}
.save {
  height: 50px;
  background-color: rgba(0, 0, 0, 0.7);
}
.save:hover {
  background-color: rgba(50, 50, 50, 0.8);
}
.cke {
  width: 80%!important;
  float: right;
  margin: 0;
  border: none!important;
  margin-bottom: 50px!important;
}
@media screen and (max-width: 767px) {
  .cke {
    width: 100%!important;
    margin-top: 10px!important;
    height: 250px;
  }
}
.prev {
  background-color: white;
  height: 40px;
  padding: 8px;
  width: 100%;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
}
/* #start{
	height: 100%;
	width: 100%;
	position: relative;
	padding: 0;
	.bg{
		position: absolute;
		min-width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	.tabel{
		display: table;
		position: absolute;
		width: 100%;
		height: 100%;
		span{
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			text-align: center;
			img{
				width: 20%;
			}
		}
	}
} */
#posts ul {
  margin: 0;
  padding: 0;
}
#posts ul li {
  list-style-type: none;
}
#done .message {
  border: 1px solid black;
  padding: 20px;
  display: inline-block;
}
#done .message a {
  font-size: 14px;
}
.no-marg {
  margin: 0;
}
html body section img[src=""] {
  display: none;
}
.save {
  margin: 0;
}
.parsley-errors-list li {
  font-size: 0.8rem;
  margin-left: 20%;
  color: #B94A48;
}
@media screen and (max-width: 767px) {
  .parsley-errors-list li {
    margin-left: 0;
  }
}
.parsley-error {
  margin-bottom: 15px;
}
