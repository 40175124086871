section{
	/* min-height: 100%; */
	width: 100%;
	padding: 0;
	/* min-height: 100%; */
	padding: 75px 0;
	position: relative;
	
	@media @mobile {
		padding: 35px 0;
	}
}
.bg{
	position: absolute;
	min-width: 100%;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	}
.tabel{
	display: table;
	position: absolute;
	width: 100%;
	height: 100%;
	.tabelcell{
		display: table-cell;
		width: 100%;
		height: 100%;
		vertical-align: middle;
		text-align: center;
	}
}
section.inner{
	width: 90%;
	margin: auto;
	@media @tablet {
		width: 95%;
	}
}
#start{
	height: 100%;
	width: 100%;
	.tabel{
		span{
			img{
				width: 20%;
				@media @mobile {
					width: 50%;
				}
			}
		}
	}
}
#start,
#welcome,
#erster_tag,
#weiteres{
	height: 100%;
	width: 100%;
	position: relative;
	background-size: cover;
	padding: 0;
	.tabel{
		.tabelcell{
			>span{
				color: white;
				text-transform: uppercase;
				background-color: rgba(0, 0, 0, 0.9);
				font-size: 3.5rem;
				font-family: 'areal';
				padding: 25px;
				@media @tablet{
					display: block;
					width: 80%;
					margin: auto;
				}
			}
		}
	}
}
#pate{
	.clearfix();
	div{
		float: left;
		width: 50%;
		img{
			margin: auto;
			display: block;
			margin-bottom: 40px;
		}
		&.left{
			padding-right: 15px;
		}
		&.right{
			padding-left: 15px;
		}
	}
}
#pate{
	.clearfix();
	.pate-person{
		width: 100%;
		float: left;
		margin: 25px 0;
		.clearfix();
	.img-box{
		float: left;
		width: 100%;
		margin-bottom: 15px;
		img{
			display: block;
			border-radius: 100px;
			width: 200px;
			margin: auto;
		}
		@media @mobile{
			img{
				width: 60%;
			}
		}
	}
	.pate-text{
		text-align: center;
		width: 100%;
	}
	}
}
#login{
	.clearfix();
	.container{
		width: 50%;
		margin: auto;
		.clearfix();
		.a{
			width: 50%;
			display: block;
			position: relative;
			padding-top: 50%;
			float: left;
			&.hover>span>.tabel>span,
			&.clicked>span>.tabel>span{
				display: table-cell;
				width: 100%;
				background-color: rgba(0, 0, 0, 0.8)
			}
			img{
				width: 100%;
				max-width: none;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: 0;
			}
			.over{
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				.tabel{
					display: table;
					width: 100%;
					height: 100%;
					span{
						vertical-align: middle;
						text-align: center;
						color: white;
						display: none;
						a{
							color: white;
							text-decoration: none;
						}
					}
				}
			}
		}
		@media @tablet {
			width: 100%;
			.a{
				width: 25%;
				padding-top: 25%;
			}
		}
		@media @mobile {
			.a{
				width: 80%;
				float: none;
				padding-top: 80%;
				margin: 0 auto 20px;
			}
		}
	}
}
#crossmedia{
	img{
		margin-top: 60px;
	}
}
#alarmanlage{
	.clearfix();
	div{
	.clearfix();
		&.left{
			padding-right: 15px;
			@media @mobile {
				padding: 0;
			}
		}
		&.right{
			padding-left: 15px;
			@media @mobile {
				padding: 0;
			}
		}
	}
}
#organigramm{
	img{
		max-width: 100%;
		max-height: 850px;
		margin: auto;
		display: block;
	}
}
#gl{
	.clearfix();
	img{
		width: 60%;
		display: block;
		margin: 20px auto 150px;
		}
	.gl-person{
		width: 50%;
		float: left;
		margin: 0 0 80px 0;
		.clearfix();
		.img-box{
			float: left;
			width: 100%;
			margin-bottom: 15px;
			img{
				display: block;
				border-radius: 100px;
				width: 200px;
				margin: auto;
				@media @mobile {
					width: 60%;
				}
			}
		}
	.gl-text{
		text-align: center;
		.clearfix();
	}
	}
	@media @mobile {
		img{
			width: 100%;
		}
		.gl-person{
			width: 100%;
			float: none;
			margin-bottom: 80px;
		}
	}
}
#level{
	img{
		max-width: 100%;
		margin-bottom: 150px;
	}
}
#orientierung{
	h2{
		margin-left: 10%;
	}
}
#cars{
	div{
		div{
			img{
				width: 33%;
				float: left;
				border-radius: 100%;
				padding: 5%;
				@media @mobile {
					display: none;
				}
			}
		}
	}
}
#notfall{
	img{
		width: 90%;
		&.mob{
			display: none;
		}
	}
	@media @mobile {
		img{
			&.destab{
				display: none;
			}
			&.mob{
				display: block;
				width: 100%;
			}
		}
	}
		
}
#evakuation{
	h2{
		margin-left: 10%;
	}
	p{
		margin-left: 10%;
	}
}
#email-adressen{
	table{
		/* margin: auto; */
		th{
		text-align: left;
		padding-top: 50px;
		}
		tr{
			td{
				padding: 0 7px;
			}
		}
	}
}
#email-policy{
	.signatur{
		font-family: 'calibri';
		font-size: 11px;
		a{
			font-family: 'calibri';
			font-size: 11px;
		}
	}
}
#farbkopierer{
	div{
		div{
			img{
				display: block;
				margin: 0;
				width: 100%;
				@media @mobile {
					margin-top: 50px;
				}
			}
		}
	}
}
#orientierung{

}
#branding{
	article{
		div{
			div{
			}
			img{
				margin: 0 auto;
				width: 200px;
				@media @mobile {
					margin: 0;
					width: 150px;
					margin-bottom: 50px;
				}
			}
		}
	}
}
#briefschaft{
	img{
		width: 100%;
		display: block;
	}
}

.float-box{
	.clearfix();
	.left{
		float: left;
		width: 50%;
	}

	.right{
		float: right;
		width: 50%;
	}
	@media @mobile {
		.left{
			float: none;
			width: 100%;
		}
		.right{
			float: none;
			width: 100%;
		}
	}
}
#probezeit{
	img{
		&.destab{
			display: block;
			height: 150px;
			@media @mobile {
				display: none;
			}
		}
		&.mob{
			display: none;
			@media @mobile {
				display: block;
				width: 50%;
				margin: 30px auto 0;
			}
		}
	}
}
#meet-the-ceo{
	img{
		border-radius: 500px;
		@media @mobile {
			margin: 0 auto 30px;
			margin-bottom: 50px;
			width: 60%;
		}
	}
	@media @mobile {
		.table-cell{
			width: 100%;
			display: block;
		}
	}
}
.hidden{
	display: none!important;
}
.table{
	display: table;
	.table-cell{
		padding-left: 2rem;
		display: table-cell;
		&:first-child{
			padding-left: 0;
		}
		&.middle{
			vertical-align: middle;
		}
	}
}
.no-marg{
	margin: 0;
	margin-left: 0;
}
.select-view {
  height: 45px;
  margin: 0 0 5px 0;
  background-color: white;
}
.select-view span {
  width: 15%;
  display: block;
  float: left;
  color: black;
  overflow: hidden;
  padding: 0 15px;
}
.select-view span.large {
  width: 27.5%;
}
.select-view:hover {
  background-color: #dfdfdf;
}
.select-view.top {
  font-weight: bold;
  background-color: black;
  padding: 10px;
}
.select-view.top span {
  color: white;
}
.select-view a {
  display: block;
  padding: 10px;
}