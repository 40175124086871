@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?focex5');
	src:url('../fonts/icomoon.eot?#iefixfocex5') format('rembedded-opentype'),
		url('../fonts/icomoon.ttf?focex5') format('truetype'),
		url('../fonts/icomoon.woff?focex5') format('woff'),
		url('../fonts/icomoon.svg?focex5#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-e:before {
	content: "\ea50";
}

.icon-e2::after {
	content: "\ea50";
	transform: rotate(180deg);
	font-family: 'icomoon';
	display: inline-block;
	width: 1rem;
	padding-right: 2rem;
}