article.sheet{
	background-color: white;
	padding: 7% 10%;
	.clearfix();
	h2{
		margin: 0 0 45px 0;
		text-align: left;
	}
	img{
		display: block;
		margin: 20px 0;
		max-width: 100%;
		@media @mobile {
			width: auto;
			max-width: 100%;
		}
	}
	@media @tablet {
		padding: 10% 5%;
	}
}
