	#out-nav{
		position: fixed;
		z-index: 100;
		display: block;
		width: 100%;
		top: 0;
		bottom: 0;
		left: 100%;
		#top-nav{
			position: fixed;
			z-index: 100;
			right: 0;
			.clearfix();
			ul{
				margin: 0;
				padding: 0;
				height: 50px;
				width: 50px;
				float: right;
				li{
					height: 50px;
					list-style-type: none;
					margin: 0;
					a.toggler{
						display: block;
						width: 50px;
						height: 50px;
						margin: 0;
						padding: 10px 10px 10px;
						background-color: rgba(0, 0, 0, 0.7);
						span{
							display: block;
							margin: 6px auto 0;
							width: 100%;
							height: 2px;
							background-color: #fff;
							opacity: 1;
						}
						&:hover{
							background-color: rgba(50, 50, 50, 0.8);
						}	
					}
				}
			}
	}
	#bottom-nav{
		position: fixed;
		bottom: 0;
		float: right;
		z-index: 100;
		float: right;
		right: 0;
		ul{
			padding: 0;
			margin: 0;
			.clearfix();
			li{
				list-style-type: none;
				float: left;
				margin: 0;
				a{
					color: white;
					display: block;
					text-decoration: none;
					font-size: 20px;
					font-weight: bold;
					background-color: rgba(0, 0, 0, 0.7);
					padding: 20px 10px 10px 10px;
					height: 50px;
					width: 50px;
					text-align: center;
					margin: 0;

					&:hover{
						background-color: rgba(50, 50, 50, 0.8);
					}
					&.mirror{
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						-o-transform: rotate(180deg);
						transform: rotate(180deg);
					}
					&.toggler{
						display: block;
						width: 50px;
						height: 50px;
						margin: 0;
						padding: 10px 10px 10px;
						background-color: rgba(0, 0, 0, 0.7);
						span{
						display: block;
						margin: 6px auto 0;
						width: 100%;
						height: 2px;
						background-color: #fff;
						opacity: 1;
					}
					&:hover{
						background-color: rgba(50, 50, 50, 0.8);
					}	
					}
				}
			}
		}
	}
	#out{
	background-color: black;
		position: relative;
		top: 0;
		height: 100%;
		left: 0%;
		padding: 30px 0 0 30px;
		transition: all 0.5s ease-out;

		h4{
				color: white;
			}
		>ul{
			padding: 0;
			margin: 0;
			li{
				list-style-type: none;
				a{
					font-family: 'areal';
					color: white;
					text-decoration: none;
					display: block;
					padding: 10px;
					text-transform: uppercase;
					&:hover{
						background-color: rgba(50, 50, 50, 0.8);
					}
					/* &.sub-close::after{
						transform: rotate(360deg);
					} */
				}
			}
			ul.sub-menu{
				padding: 0;
				display: none;
				li{
					padding: 0;
					a{
						padding-left: 35px;
						font-size: 16px;
					}
					ul{
						li{
							a{
								font-size: 15px;
							}
						}
					}
				}
				&.sub-open{
					display: block;
				}
			}
		}
	}
}
#out-nav{
	padding: 0;
}
#out-nav nav#out.move-nav {
		left: -25%;
		overflow-y: scroll;
		margin-right: -17px;
		padding-bottom: 100px;
		@media screen and (max-width: 1500px){
			left: -45%;
		}
		@media @tablet {
			left: -55%;
		}
		@media @mobile {
			left: -85%;
		}
	}

.cross-1 {
		-webkit-transform: rotate(45deg) translate(5px, 7px);
		-moz-transform: rotate(45deg) translate(5px, 7px);
		-ms-transform: rotate(45deg) translate(5px, 7px);
		-o-transform: rotate(45deg) translate(5px, 7px);
		-webkit-transform: rotate(45deg) translate(5px, 7px);
		-ms-transform: rotate(45deg) translate(5px, 7px);
		-o-transform: rotate(45deg) translate(5px, 7px);
		transform: rotate(45deg) translate(5px, 7px);
		margin: 0;
	}

	.cross-2 {
		-webkit-transform: rotate(-45deg) translate(-3px, -2px);
		-moz-transform: rotate(-45deg) translate(-3px, -2px);
		-ms-transform: rotate(-45deg) translate(-3px, -2px);
		-o-transform: rotate(-45deg) translate(-3px, -2px);
		-webkit-transform: rotate(-45deg) translate(-3px, -2px);
		-ms-transform: rotate(-45deg) translate(-3px, -2px);
		-o-transform: rotate(-45deg) translate(-3px, -2px);
		transform: rotate(-45deg) translate(-3px, -2px);
		margin: 0;
	}

	.remove {
		opacity: 0!important;
	}
	.animate{
		-webkit-transition: all 0.4s ease 0s;
							-moz-transition: all 0.4s ease 0s;
							-ms-transition: all 0.4s ease 0s;
							-o-transition: all 0.4s ease 0s;
							-webkit-transition: all 0.4s ease 0s;
							-o-transition: all 0.4s ease 0s;
							transition: all 0.4s ease 0s;
	}