html {
	height: 100%;
	font-size: 14px;
	@media @tablet {
		font-size: 12px;
	}
	@media @mobile {
		font-size: 10px;
	}
}
body {
	width: 100%;
	min-width: 320px;
	height: 100%;
	overflow-y: scroll;
	margin: 0;
	background-color: #f1f1f1;

}
.wrapper {
	position: relative;
	width: 100%;
	height: 100%;
}
.scrolloff {
        pointer-events: none;
    }
//Selection//////////////////////////////////////////////////////////////////////////////////
::-webkit-selection {
	background-color: #000000;
	color: #ffffff;
}
::-moz-selection {
	background-color: #000000;
	color: #ffffff;
}
::-ie-selection {
	background-color: #000000;
	color: #ffffff;
}
::-o-selection {
	background-color: #000000;
	color: #ffffff;
}
::selection {
	background-color: #000000;
	color: #ffffff;
}
//Fonts//////////////////////////////////////////////////////////////////////////////////
@font-face {
  font-family: 'areal';
  src: url('../fonts/areal/areal.eot') format('eot'), url('../fonts/areal/areal.woff') format('woff'), url('../fonts/areal/areal.ttf') format('truetype'), url('../fonts/areal/areal.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'catalog_italic';
  src: url('../fonts/catalog/catalog_italic.eot') format('eot'), url('../fonts/catalog/catalog_italic.woff') format('woff'), url('../fonts/catalog/catalog_italic.ttf') format('truetype'), url('../fonts/catalog/catalog_italic.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'catalog_regular';
  src: url('../fonts/catalog/catalog_regular.eot') format('eot'), url('../fonts/catalog/catalog_regular.woff') format('woff'), url('../fonts/catalog/catalog_regular.ttf') format('truetype'), url('../fonts/catalog/catalog_regular.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'karla_regular';
  src: url('../fonts/karla/Karla-Regular-webfont.eot') format('eot'), url('../fonts/karla/Karla-Regular-webfont.woff') format('woff2'), url('../fonts/karla/Karla-Regular-webfont.woff') format('woff'), url('../fonts/karla/Karla-Regular-webfont.ttf') format('truetype'), url('../fonts/karla/Karla-Regular-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'karla_regular_italic';
  src: url('../fonts/karla/Karla-Italic-webfont.eot') format('eot'), url('../fonts/karla/Karla-Italic-webfont.woff') format('woff2'), url('../fonts/karla/Karla-Italic-webfont.woff') format('woff'), url('../fonts/karla/Karla-Italic-webfont.ttf') format('truetype'), url('../fonts/karla/Karla-Italic-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'karla_bold_italic';
  src: url('../fonts/karla/Karla-Bold-webfont.eot') format('eot'), url('../fonts/karla/Karla-Bold-webfont.woff') format('woff2'), url('../fonts/karla/Karla-Bold-webfont.woff') format('woff'), url('../fonts/karla/Karla-Bold-webfont.ttf') format('truetype'), url('../fonts/karla/Karla-Bold-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'karla_regular_italic';
  src: url('../fonts/karla/Karla-BoldItalic-webfont.eot') format('eot'), url('../fonts/karla/Karla-BoldItalic-webfont.woff') format('woff2'), url('../fonts/karla/Karla-BoldItalic-webfont.woff') format('woff'), url('../fonts/karla/Karla-BoldItalic-webfont.ttf') format('truetype'), url('../fonts/karla/Karla-BoldItalic-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'roboto_light';
  src: url('../fonts/roboto/Roboto-Light-webfont.eot') format('eot'), url('../fonts/roboto/Roboto-Light-webfont.woff') format('woff'), url('../fonts/roboto/Roboto-Light-webfont.ttf') format('truetype'), url('../fonts/roboto/Ratalo-Light-webfont.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'roboto_light_italic';
  src: url('../fonts/roboto/Roboto-LightItalic-webfont.eot') format('eot'), url('../fonts/roboto/Roboto-LightItalic-webfont.woff') format('woff'), url('../fonts/roboto/Roboto-LightItalic-webfont.ttf') format('truetype'), url('../fonts/roboto/Roboto-LightItalic-webfont.svg#svgFontName') format('svg');
}

/* *{
	font-family: 'catalog_regular';
	font-size: 1.5rem;
} */
h1 {
	font-family: 'areal';
	font-size: 3.5rem;
	margin: 30px 0;
	text-transform: uppercase;
}
h2 {
	font-family: 'areal';
	font-size: 3rem;
	margin: 30px 0 20px;
	text-transform: uppercase;
}
h3 {
	font-family: 'areal';
	font-size: 2.5rem;
	margin: 30px 0 15px;
}
h4 {
	font-family: 'areal';
	font-size: 2.2rem;
	margin: 30px 0 10px;
}
h5 {
	font-family: 'areal';
	font-size: 1.8rem;
	margin: 30px 0 5px;
}
h6 {
	font-family: 'areal';
	font-size: 1.5rem;
	margin: 30px 0 5px;
}
p{
	font-family: 'catalog_regular';
	font-size: 1.5rem;
	margin: 5px 0;
}


b{
	font-family: 'catalog_regular';
	font-size: 1.5rem;
}
a{
	font-family: 'catalog_regular';
	font-size: 1.5rem;
	margin: 5px 0;
	color: black;
}
a.mail{
	display: block;
	text-decoration: none;
}
ul,
ol{
	padding: 0 20px;
	li{
		font-family: 'catalog_regular';
	    font-size: 1.5rem;
	    margin: 5px 0;
	}
}
table{
	word-break: break-word;
	border-color: gray;
	border-spacing: 2px;
	border-collapse: collapse;
	th{
		font-size: 1.5rem;
		text-align: left;
	}
	tr{
		border-color: inherit;
		&.grey{
			background-color: #efefef;
		}
		td{
			font-size: 1.5rem;
			padding-right: 40px;
			padding-bottom: 5px;
			vertical-align: top;
			border: 1px solid #e0e0e0;
			padding: 5px;
		}
	}
	@media @mobile {
		width: 100%;
		th{

		}
		tr{
			td{
				width: 50%;
			}
		}
	}
}