.parsley-errors-list{
	li{
		font-size: 0.8rem;
		margin-left: 20%;
		color: #B94A48;
		@media @mobile{
			margin-left: 0;
		}
	}

}
.parsley-error{
	margin-bottom: 15px;
}